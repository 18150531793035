function NoMode() {

    return(
        <div className="auth-main">
            <h1 className="auth-header">
                No Authenication Mode Set
            </h1>
        </div>
    )
}

export default NoMode;